import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Masonry from "../../components/masonry"
import { graphql } from "gatsby"

export default ({ data }) => (
  <Layout>
    <SEO title="Commercial" />

    <section className="hero is-medium is-white">
      <div className="hero-body">
        <div className="container">
          <h1 className="title is-spaced is-size-3">Commercial</h1>
          <h3 className="is-size-5">
            As an Accredited Professional Photographer John specialises in
            photography aimed at business and advertising. He has vast
            experience in working to a brief from the client, and will always
            look for ways to add value to the client’s business. The commercial
            work extends from mining companies, small business, government,
            product photography, aerial drone work and real estate.
          </h3>
        </div>
      </div>
    </section>
    <section>
      <Masonry
        items={data.commercial.edges.map(({ node }) => ({
          image: { ...node.childImageSharp.fluid },
          publicURL: node.publicURL,
          name: node.name,
        }))}
        itemsPerRow={[1, 5]}
      />
    </section>
  </Layout>
)

export const data = graphql`
  query {
    commercial: allFile(
      filter: { relativeDirectory: { eq: "gallery/commercial" } }
    ) {
      edges {
        node {
          id
          publicURL
          name
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
